import React from 'react'
import { graphql } from 'gatsby'
import BlockContent from '../components/block-content'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Img from 'gatsby-image'
import Layout from '../containers/layout'
// import BackgroundImage from 'gatsby-background-image'

export const query = graphql`
  query ContactPageQuery {
    page: sanityPage(_id: { regex: "/(drafts.|)contact/" }) {
      title
      _rawPortablebody
    }

    background: sanityBackgrounds {
      contactPage {
        _key
        _type
        alt
        asset {
          fluid {
            base64
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
          }
        }
      }
    }

    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
  }
`

const ContactPage = props => {
  const { data, errors } = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const page = data.page
  const background = (data || {}).background
  const site = (data || {}).site

  if (!page) {
    throw new Error(
      'Missing "Contact" page data. Open the studio at http://localhost:3333 and add "Contact" page data and restart the development server.'
    )
  }

  return (
    <Layout pageLayout='normal-footer'>
      <SEO title={site.title} description={site.description} keywords={site.keywords} />
      <article className='sections'>
        <section className='page-section white start'>

          <div className='mainImage hero--contact hero contact'>

            <div className='content-wrapper contact-page'>
              <div className='content'>
                <form
                  className='contact-form card'
                  name='contact'
                  method='POST'
                  netlify-honeypot='bot-field'
                  data-netlify='true'
                  action='/thanks'
                >
                  <h1>{page.title}</h1>

                  {page._rawPortablebody && <BlockContent blocks={page._rawPortablebody || []} />}

                  <input type='hidden' name='form-name' value='contact' />
                  <input type='hidden' name='subject' value='Kontakt form: Atelierfontange.de' />
                  <p className='hidden'>
                    <label>Don’t fill this out if you're human: <input name='bot-field' /></label>
                  </p>
                  <p className='form-field'>
                    <label htmlFor='name' className='control-label'>
                      <span className='label'>Name</span>
                      <input type='text' name='name' required='required' />
                    </label>

                  </p>
                  <p className='form-field'>
                    <label htmlFor='email' className='control-label'>
                      <span className='label'>Email</span>
                      <input type='email' name='email' required='required' />
                    </label>

                  </p>
                  <p className='form-field'>
                    <span htmlFor='message' className='label'>Persönliche Nachricht</span>
                    <textarea required='required' name='message' />
                  </p>
                  <p className='form-field checkbox-datenschutz'>
                    <input htmlFor='datenschutz' type='checkbox' name='datenschutz' id='datenschutz' required='required' />
                    <span className='label'>
Ich stimme zu, dass meine Angaben aus dem Kontaktformular zur Beantwortung meiner Anfrage erhoben und verarbeitet werden. Die Daten werden nach abgeschlossener Bearbeitung Ihrer Anfrage umgehend gelöscht. Detaillierte Informationen zum Umgang mit Nutzerdaten finden Sie in unseren Datenschutzerklärung. * </span>
                  </p>
                  {/* <p className='form-field'>
                    <div className='recaptcha' data-netlify-recaptcha='true'>recaptcha</div>
                  </p> */}
                  <div className='button-container'>
                    <button type='submit' className='button'>
                      <span>Senden</span>
                    </button>
                  </div>
                </form>
              </div>

            </div>
            <Img loading='eager' fluid={background.contactPage.asset.fluid} />

          </div>

        </section>
      </article>
    </Layout>

  )
}
ContactPage.defaultProps = {
  data: {
    page: {
      title: 'No title'
    }
  }
}
export default ContactPage
